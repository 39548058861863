//angular imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//rxjs imports
import { Observable, BehaviorSubject } from 'rxjs'


//local imports
import { GroupBy } from '../_models/groupBy';
import { PersonaGroupByRel } from '../_models/personaGroupByRel';
import { Persona } from '../_interfaces/persona';
import { AppConfig } from '../app-config';

@Injectable({
  providedIn: 'root'
})
export class GroupByService {
  
  groupBysUpdated: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(private http: HttpClient, private applicationConfig: AppConfig) {    
  }
      
  getStandardForLibrary() {
    return this.http.get<GroupBy[]>(`${this.applicationConfig.applicationUri}/v2/groupby/getstandardforlibrary`);
  }
  getStandardForCatalog() {
    return this.http.get<GroupBy[]>(`${this.applicationConfig.applicationUri}/v2/groupby/getstandardforcatalog`);
  }
  getAllGroupBysForPersonaInLibrary(persona: Persona) {
    return this.http.get<PersonaGroupByRel[]>(`${this.applicationConfig.applicationUri}/v2/groupby/library/${persona.id}`);
  }
  getAllGroupBysForPersonaInCatalog(persona: Persona) {
    return this.http.get<PersonaGroupByRel[]>(`${this.applicationConfig.applicationUri}/v2/groupby/catalog/${persona.id}`);
  }  
  getTopLevelGroupBysForPersonaInLibrary(persona: Persona): Observable<GroupBy[]> {
    return this.http.get<GroupBy[]>(`${this.applicationConfig.applicationUri}/v2/groupby/toplevellibrary/${persona.id}`);
  }
  getTopLevelGroupBysForPersonaInCatalog(persona: Persona): Observable<GroupBy[]> {
    return this.http.get<GroupBy[]>(`${this.applicationConfig.applicationUri}/v2/groupby/toplevelcatalog/${persona.id}`);
  }
  saveGroupByForLibrary(persona: Persona, ids: string[]) {
    return this.http.post(`${this.applicationConfig.applicationUri}/v2/groupby/saveforlibrary`, { PersonaId: persona.id, GroupByIDs: ids });
  }
  saveGroupByForCatalog(persona: Persona, ids: string[]) {
    return this.http.post(`${this.applicationConfig.applicationUri}/v2/groupby/saveforcatalog`, { PersonaId: persona.id, GroupByIDs: ids });
  }
}
