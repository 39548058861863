//angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, ParamMap, Params } from '@angular/router';
import { Subscription } from 'rxjs';
//msal
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

//local
import { ReferenceType } from '../_enums/referenceType';
import { expandCamelCase } from '../_helpers/camelCaseHelper';
import { FilterGroup } from '../_interfaces/filterGroup';
import { Document, DocumentGroup } from "../_models/document";
import { Filter } from '../_models/filter';
import { FiltersSet, FiltersSetBehaviour } from '../_models/filtersSet';
import { UserNotificationService } from './notification.service';
import { AppConfig } from '../app-config';
import { PropertyCategory } from '../_interfaces/properties';
import { PropertyService } from '../_services/property.service';
import { aggregateProperties } from '../_helpers/propertyAggregator';
import { Breadcrumb } from '../_interfaces/breadcrumb';
import { WayFinderTile, WayFinderTileGroup } from '../_models/wayfinderTiles';
import { SearchText } from '../_interfaces/searchText';
import { getReferenceTypeName } from '../_helpers/referenceTypeHelper';
import { CategoryService } from '../_services/category.service';
import { CategoryGroup } from '../_interfaces/categoryGroup';
import { buildUrl } from '../_helpers/blobUrlBuilder';
import { Report } from '../_interfaces/report';
import { PersonaService } from './persona.service';

@Injectable({
    providedIn: 'root'
})

export class LibraryNavigationService {


    private currentURLFilters: Filter[] = [];
    private currentURLFilterLookup: boolean[][] = [];
    private currentURLWayFinders: Filter[] = [];
    private currentURLWayFinderLookup: boolean[][] = [];
    public offset: number = 0;

    public loadingMain: boolean = false;
    public loadingMoreData: boolean = false;
    public currentSearchString: string = '';
    public view: string = '';
    public showingFiltersPane: boolean = false;
    public navigationFilters: FiltersSet = new FiltersSet([], []);
    public breadCrumb: Breadcrumb[] = [];
    public filtered: boolean = false;
    public wayFinderTileGroups: WayFinderTileGroup[] = [];
    public selectedWayFinderTile: WayFinderTile;

    public homePageStarred: Document[] = [];
    public homePageStarredTotal: number = 0;
    public homePageRecent: Document[] = [];
    public homePageRecentTotal: number = 0;
    public homePageRecentlyUploaded: Document[] = [];
    public homePageRecentlyUploadedTotal: number = 0;
    public homePageStarredFileTypes: number[] = [];
    public homePageRecentFileTypes: number[] = [];
    public homePageRecentlyUploadedFileTypes: number[] = [];

    public viewingDocuments: boolean = true;
    public documents: Document[] = [];
    public documentIndices: number[] = [];
    public documentFileTypes: number[] = [];
    public totalRecordsAvailable: number = 0;
    public chronologicallyGroupedDocuments: DocumentGroup[];
    public allDocumentsUrl: string;

    public supersededVersions: boolean = false;

    public currentUser: AccountInfo;

    private queryID: number = 0;

    public currentViewItemTotal: number = 0;

    emptyGuid: string = '00000000-0000-0000-0000-000000000000';

    public searchStrings: string[] = [];
    currentPageType: ReferenceType = ReferenceType.Null;
    currentLocation: string = '';
    wayfinderCategoryGroups: WayFinderTileGroup[] = [];
    searchTextQryStr: string = '';

    private blobUrl: string;
    private sas: string;

  public wfDepth: number = 0;
  public reports: Report[] = [];

  selectedReport: Report;

    constructor(
        private authenticationService: MsalService,
        private activatedRoute: ActivatedRoute,
        private http: HttpClient,
        public router: Router,
        private notificationService: UserNotificationService,
        private applicationConfig: AppConfig,
        private propertyService: PropertyService,
        private categoryService: CategoryService,
        private personaService: PersonaService,
    ) {
        this.currentUser = this.authenticationService.instance.getActiveAccount();
    }

  public removeReport(id: string) {
    this.reports = this.reports.filter(report => report.id !== id);
    this.currentViewItemTotal = this.reports.length;
  }

    selectWayFinderTile(wayFinderTile: WayFinderTile) {
        this.deselectWayFinderTile();

        let departmentId: string = this.emptyGuid;

        this.currentURLWayFinders.forEach((filter: Filter, index: number) => {
            if (wayFinderTile.referenceType == ReferenceType.Room && filter.referenceType == ReferenceType.Department) departmentId = filter.id;
        });

        this.selectedWayFinderTile = wayFinderTile;
        this.selectedWayFinderTile.selected = true;
    }

    deselectWayFinderTile() {
        if (this.selectedWayFinderTile !== undefined) {
            this.selectedWayFinderTile.selected = false;
            this.selectedWayFinderTile = undefined;
        }
    }

    toggleSupersededVersions() {
        this.supersededVersions = !this.supersededVersions;
        this.offset = 0;
        this.getLibraryPage();
    }

    navigate(url: string = null): void {
        if (url == null) url = this.router.url.split('?')[0];

        var params: string[] = [];

        if (this.currentSearchString.length > 0) params['search'] = this.currentSearchString;
        this.offset = 0;
        this.navigationFilters.getSelectedFilters().forEach((filter: Filter, index: number) => {
            if (this.currentURLWayFinderLookup[filter.referenceType] === undefined || this.currentURLWayFinderLookup[filter.referenceType][filter.id] === undefined) {
                params['f.' + filter.referenceType + '.' + filter.id] = true;
            }
        });
        for (var i = 0; i < this.searchStrings.length; i++) {
            if (this.searchStrings[i] != undefined && this.searchStrings[i] != '') {
                params['search-' + i] = this.searchStrings[i];
            }
        }
        this.router.navigate([url], { queryParams: params });
    }

    public getDocument(id: string): Document {
        if (this.documentIndices[id] === undefined) return undefined;
        return this.documents[this.documentIndices[id]];
    }

    public revisingDocument(id: string, revising: boolean): void {
        if (this.documentIndices[id] !== undefined) this.documents[this.documentIndices[id]].revising = revising;
    }

    public isRevisionPending(id: string) {
        if (this.documentIndices[id] !== undefined) return this.documents[this.documentIndices[id]].revising;
        return false;
    }

    public removeUploadingHighlights(): void {
        this.documents.forEach((document: Document, index: number) => {
            document.revising = false;
        });
    }


    public urlUpdated(params: ParamMap, queryParams: Params) {
        this.documents = [];
        this.homePageStarred = [];
        this.homePageStarredTotal = 0;
        this.homePageRecent = [];
        this.homePageRecentTotal = 0;
        this.homePageRecentlyUploaded = [];
        this.homePageRecentlyUploadedTotal = 0;
        this.homePageStarredFileTypes = [];
        this.homePageRecentFileTypes = [];
        this.homePageRecentlyUploadedFileTypes = [];

        this.viewingDocuments = true;
        this.documents = [];
        this.documentIndices = [];
        this.documentFileTypes = [];
        this.totalRecordsAvailable = 0;
        this.chronologicallyGroupedDocuments = [];

        this.offset = 0;
        this.searchStrings = [];
        this.searchTextQryStr = '';
        this.currentPageType = 7;
        this.wfDepth = 0;

        // Determine the type of view
        this.view = params.get('view') != null ? params.get('view') : 'home';
        if (this.view != 'home' && this.view != 'recent' && this.view != 'starred' && this.view != 'all' && this.view != 'wayfinder' && this.view != 'downloads') this.view = 'home';
        if (this.view == 'home' || this.view == 'wayfinder' || this.view == 'downloads') this.showingFiltersPane = false;
        else {
            this.showingFiltersPane = true;
        }
        if (this.view != 'wayfinder') this.currentLocation = 'Library';

        // Grab the wayfinders from the URL
        queryParams = this.activatedRoute.snapshot.queryParams;
        this.currentURLWayFinders = [];
        this.currentURLWayFinderLookup = [];
        for (let i = 0; i < 5; i++) {
            let param: string = params.get('wayfinder' + (i + 1));
            if (param != null) {
                // wayfinder url token is in the form a.b - a=referencetype, b=id
                let tokens: string[] = param.split('.');
                let refType: number = parseInt(tokens[0])
                let id: string = tokens.length > 1 ? tokens[1] : this.emptyGuid;
                this.currentURLWayFinders[i] = new Filter(id, refType);
                if (id != this.emptyGuid) {
                    if (this.currentURLWayFinderLookup[refType] === undefined) this.currentURLWayFinderLookup[refType] = [];
                    this.currentURLWayFinderLookup[refType][tokens[0]] = true;
                    this.wfDepth++;
                }
            }
        }

        this.currentSearchString = '';
        this.currentURLFilters = [];
        this.currentURLFilterLookup = [];
        for (let i in queryParams) {
            if (i.indexOf('f.') == 0) {
                let tokens: string[] = i.split('.');
                if (tokens.length == 3) {
                    let refType: number = parseInt(tokens[1]);
                    let id: string = tokens[2];
                    let f: Filter = new Filter(id, refType);
                    this.currentURLFilters.push(f);
                    if (this.currentURLFilterLookup[refType] === undefined) this.currentURLFilterLookup[refType] = [];
                    this.currentURLFilterLookup[refType][id] = true;
                }
            } else if (i.indexOf('search-') > -1) {
                var tokens = i.split('-');
                var rt = Number(tokens[1]);
                if (!isNaN(rt)) {
                    this.searchStrings[rt] = queryParams[i];
                    this.searchTextQryStr = this.searchTextQryStr + (this.searchTextQryStr.length > 0 ? '&' : '') + 'search-' + rt + '=' + encodeURIComponent(queryParams[i]);
                }
            } else if (i == 'search') {
                this.currentSearchString = queryParams[i];
            }
        }
        if (this.searchTextQryStr.length > 0) this.searchTextQryStr = '?' + this.searchTextQryStr;

        if (this.currentURLFilters.length > 0 || this.currentSearchString.length > 0) this.filtered = true;
        else this.filtered = false;

        this.navigationFilters = new FiltersSet([...this.currentURLWayFinders, ...this.currentURLFilters], []);
        //this.showingFiltersPane = this.view != 'home' && this.view != 'wayfinder';

        this.breadCrumb = [];
        this.breadCrumb.push({ name: 'Library', url: '/home/documents/view/home' });
        if (this.view == 'recent') this.breadCrumb.push({ name: 'Recent', url: '/home/documents/view/recent' });
        if (this.view == 'starred') this.breadCrumb.push({ name: 'Starred', url: '/home/documents/view/starred' });
        if (this.view == 'downloads') this.breadCrumb.push({ name: 'Downloads', url: '/home/documents/view/downloads' });

      if (this.view != 'wayfinder' && this.view != 'downloads') this.getLibraryPage();
      else if (this.view == 'downloads') this.getDownloadsPage();
      else {
        // If the user is using wayfinders, get the next level
        if (this.currentURLWayFinders.length > 0) this.getWayFindersNextLevel();
      }
    }

    searchNavigationFilters(target: EventTarget | null) {
        const inputElement = target as HTMLInputElement;
        if (inputElement) {
            let s: string = inputElement.value;
            this.navigationFilters.search(s);
        }
    }
/*
    getSearchTexts(): SearchText[] {
        let searchTexts: SearchText[] = [];
        for (var i = 0; i < this.searchStrings.length; i++) {
            if (this.searchStrings[i] != undefined && this.searchStrings[i] != '')
                searchTexts.push({ ReferenceType: i, Text: this.searchStrings[i] });
        }
        return searchTexts;
    }
*/
    filtersButtonClicked() {
        if (this.view == 'home') {
            this.navigate('/home/documents/view/all');
            this.showingFiltersPane = true;
        } else if (this.view == 'all') {
            this.navigate('/home/documents/view/home');
            this.showingFiltersPane = false;
        } else {
            if (this.view == 'wayfinder' && !this.viewingDocuments) this.navigate('/home/documents/view/all');
            this.showingFiltersPane = !this.showingFiltersPane;
        }
    }

    toggleFavourite(document: Document) {
        let payload = {
            LibraryId: document.id,
        }

        if (!document.starred) {

            this.http
                .post(`${this.applicationConfig.applicationUri}/v2/document/addstarreddocument`, payload)
                .subscribe(
                    {
                        next: _ => {
                            this.reloadAfterStarring(document.id, true);
                        },
                        error: error => {
                            this.notificationService.showNotification(`An unexpected error occurrred. ${error?.error ? error.error : ''}`, 'error');
                        }
                    });

        } else {
            this.http
                .post(`${this.applicationConfig.applicationUri}/v2/document/removestarreddocument`, payload)
                .subscribe({
                    next: _ => {
                        this.reloadAfterStarring(document.id, false);
                    },
                    error: error => {
                        this.notificationService.showNotification(`An unexpected error occurrred. ${error?.error ? error.error : ''}`, 'error');
                    }
                });
        }
    }

    toggleLatestFavourite(document: Document) {

        let payload = {
            LibraryId: document.latestVersionId,
        };

        if (!document.latestIsStarred) {
            this.http
                .post(`${this.applicationConfig.applicationUri}/v2/document/addstarreddocument`, payload)
                .subscribe({
                    next: _ => { this.reloadAfterStarring(document.latestVersionId, true); },
                    error: error => {
                        this.notificationService.showNotification(`An unexpected error occurrred. ${error?.error ? error.error : ''}`, 'error');
                    }
                });
            return;
        }
        
        this.http
            .post(`${this.applicationConfig.applicationUri}/v2/document/removestarreddocument`, payload)
            .subscribe({
                next: _ => { this.reloadAfterStarring(document.latestVersionId, false); },
                error: error => {
                    this.notificationService.showNotification(`An unexpected error occurrred. ${error?.error ? error.error : ''}`, 'error');
                }
            });        
    }



    reloadAfterStarring(id: string, starred: boolean) {

        [...this.documents, 
            ...this.homePageRecent, 
            ...this.homePageStarred, 
            ...this.homePageRecentlyUploaded].forEach((doc: Document, index: number) => {

            if (doc.id == id) {
                doc.starred = starred;
            }
            if (doc.latestVersionId == id) {
                doc.latestIsStarred = starred;
            }
        });

        if (this.view == 'home' || this.view == 'starred') {
            this.offset = 0;
            this.getLibraryPage();
        }
    }

    textSearch() {
        var searchBox = document.getElementById('search-box');
        if (searchBox instanceof HTMLInputElement) {
            if (this.viewingDocuments) {
              this.currentSearchString = searchBox.value.trim();
            } else {
              this.searchStrings[this.wfDepth] = searchBox.value.trim();
            }
        }
        if (this.view == 'home') this.navigate('/home/documents/view/all');
        else this.navigate();
    }

    documentDeleted(id: string): void {
        if (this.documentIndices[id] !== undefined) {
            this.documents[this.documentIndices[id]].deleted = true;
        }
    }

  getDownloadsPage(): void { 
    this.http.get<GetReportsResponse>(`${this.applicationConfig.applicationUri}/v2/report/`).subscribe({
      next: result => {
        this.reports = result.reports;
        this.currentViewItemTotal = result.reports.length;
      },
      error: error => {
        console.log(error);
      }
    });
  }

    getWayFindersNextLevel(): void {
        let searchText: string | null = this.searchStrings[this.wfDepth] !== undefined ? this.searchStrings[this.wfDepth] : null;
        this.loadingMain = true;
        this.queryID++;

        let payload = { wayfinders: this.currentURLWayFinders, textSearch: searchText, offset: this.offset, queryId: this.queryID, personaId: this.personaService.currentPersona().id, searchText: this.currentSearchString, superseded: this.supersededVersions };
        this.http
            .post<WayFinderResult>(`${this.applicationConfig.applicationUri}/v2/document/evaluatewayfinders/`, payload)
            .subscribe({
                next: result => {

                if (result.queryId != this.queryID) return;
                let baseUrl: string = '/home/documents/view/wayfinder';
                this.breadCrumb = [];
                this.breadCrumb.push({ name: 'Library', url: '/home/documents/view/home' });
                this.totalRecordsAvailable = result.documentCount;
                let qryStr: string = '';
                result.wayFinders.forEach((wayfinder: Filter, index: number) => {
                    if (wayfinder.referenceType != ReferenceType.Null) {
                        // Add the generic breadcrumb, e.g. "Department", "Category", etc
                        let name: string = getReferenceTypeName(wayfinder.referenceType);
                        if (this.searchStrings[index] != undefined && this.searchStrings[index] != '') {
                            qryStr = qryStr + (qryStr.length > 0 ? '&' : '') + 'search-' + index + '=' + encodeURIComponent(this.searchStrings[index]);
                            name += ' [Filtered]'
                        }
                        let lb: Breadcrumb = { name: name, url: baseUrl + '/' + wayfinder.referenceType + (qryStr.length > 0 ? '?' + qryStr : '') };
                        this.breadCrumb.push(lb);

                        // Now, it the current wayfinder has a value (i.e. it's not the generic breadcrumb), add the specific breadcrumb, e.g. "10 - Front Porch", etc
                        if (wayfinder.id != this.emptyGuid) {
                            let lb: Breadcrumb = { name: wayfinder.displayText, url: baseUrl + '/' + wayfinder.referenceType + '.' + wayfinder.id };
                            this.breadCrumb.push(lb);
                            baseUrl = baseUrl + '/' + wayfinder.referenceType + '.' + wayfinder.id;
                        }
                    }
                });
                
                let allUrl = baseUrl;
                
                if (result.wayFinders[result.wayFinders.length - 1].id == this.emptyGuid) allUrl = allUrl + '.0';

                    allUrl = allUrl + '/0';

                    if (result.nextLevel.length == 1) {
                        this.totalRecordsAvailable = result.nextLevel[0].itemCount;
                        //if (result.wayFinders[result.wayFinders.length - 1].referenceType != result.nextLevel[0].referenceType) {
                        //          this.breadCrumb.push(new LibraryBreadcrumb(expandCamelCase(ReferenceType[result.nextLevel[0].referenceType]), baseUrl + '/' + result.nextLevel[0].referenceType));
                        //}
                        let lb: Breadcrumb = { name: expandCamelCase(ReferenceType[result.nextLevel[0].referenceType]) + (this.searchStrings[result.nextLevel[0].referenceType] != undefined && this.searchStrings[result.nextLevel[0].referenceType] != '' ? ' [Filtered]' : ''), url: baseUrl + '/' + result.nextLevel[0].referenceType + (qryStr.length > 0 ? '?' + qryStr : '') };
                        if (this.breadCrumb[this.breadCrumb.length - 1].name != lb.name) this.breadCrumb.push(lb);
                        allUrl = baseUrl + '/' + result.nextLevel[0].referenceType + '.00000000-0000-0000-0000-000000000000/0' + (qryStr.length > 0 ? '?' + qryStr : '');
                        this.currentPageType = result.nextLevel[0].referenceType;
                        this.currentLocation = getReferenceTypeName(this.currentPageType);
                    } else {
                        this.currentLocation = '';
                        result.nextLevel.forEach((level: FilterGroup, index: number) => {
                            this.currentLocation = this.currentLocation
                                + (this.currentLocation.length > 0 ? ' / ' : '')
                                + getReferenceTypeName(level.referenceType);
                        });
                    }

                this.allDocumentsUrl = allUrl;
                if (qryStr.length > 0) qryStr = '?' + qryStr;
                this.wayfinderCategoryGroups = [];
                this.wayFinderTileGroups = [];
                
                let groupArray = this.wayFinderTileGroups;

                this.currentViewItemTotal = 0;

                for (let i = 0; i < result.nextLevel.length; i++) {
                    this.currentViewItemTotal += result.nextLevel[i].filters.length;
                    this.wayFinderTileGroups[i] = new WayFinderTileGroup(result.nextLevel[i].referenceType);
                    this.wayFinderTileGroups[i].recordCount = result.nextLevel[i].itemCount;
                    // a fix for the category and miniset sub-levels
                    let hasParent = result.nextLevel[i].filters.length > 0 && result.nextLevel[i].filters[0].referenceType == result.nextLevel[i].referenceType;
                   
                    if (hasParent) this.wayFinderTileGroups[i].recordCount = result.nextLevel[i].itemCount;
                    
                    this.wayFinderTileGroups[i].allDocumentsUrl = baseUrl + '/' + result.nextLevel[i].referenceType + '.00000000-0000-0000-0000-000000000000/0' + this.searchTextQryStr;
                    
                    for (let j = 0; j < result.nextLevel[i].filters.length; j++) {
                        let filter: Filter = result.nextLevel[i].filters[j];
                        let tile: WayFinderTile = new WayFinderTile(filter);
                        tile.url = baseUrl + '/' + filter.referenceType + '.' + filter.id + this.searchTextQryStr;
                        tile.allDocumentsUrl = baseUrl + '/' + filter.referenceType + '.' + filter.id + '/0' + this.searchTextQryStr;
                        if (filter.thumbnailUrl != null && filter.thumbnailUrl != '') {
                            tile.setThumb(buildUrl(this.blobUrl, this.sas, filter.thumbnailUrl));
                        }
                        this.wayFinderTileGroups[i].addTile(tile);
                    }
                }

                    if (this.currentPageType == ReferenceType.Category) {
                        // Category wayfinders need to be grouped according to ttheir CategoryGroups
                        this.categoryService.getAll(ReferenceType.Library).subscribe(groups => {
                            // Index the group Ids for all the categories
                            let groupLookups: string[] = [];
                            let catGroups: string[] = [];

                            groups.forEach((grp: FilterGroup) => {
                                catGroups[grp.id] = grp.name;
                                grp.filters.forEach((fltr: Filter) => {
                                    groupLookups[fltr.id] = grp.id;
                                    fltr.children.forEach((ftr: Filter) => {
                                        groupLookups[ftr.id] = grp.id;
                                    });
                                });
                            });

                            let wft: WayFinderTileGroup[] = [];
                            // stores the numerical position of a CategoryGroup in the list, indexed by id
                            let grpIndices: number[] = [];

                            this.wayFinderTileGroups.forEach((grp: WayFinderTileGroup) => {
                                grp.tiles.forEach((tile: WayFinderTile) => {
                                    let groupId: string = groupLookups[tile.id];
                                    if (grpIndices[groupId] === undefined) {
                                        let g: WayFinderTileGroup = new WayFinderTileGroup(ReferenceType.Category);
                                        g.title = catGroups[groupId];
                                        g.tiles = [];
                                        let index: number = wft.length;
                                        wft.push(g);
                                        grpIndices[groupId] = index;
                                    }
                                    wft[grpIndices[groupId]].tiles.push(tile);
                                });
                            });

                            wft = wft.sort((a, b) => a.title.localeCompare(b.title));
                            this.wayfinderCategoryGroups = wft;
                        });
                    }

                if (result.nextLevel.length == 0) {
                    this.getLibraryPage();
                    this.showingFiltersPane = true;
                    this.viewingDocuments = true;
                } 
                else {
                    this.viewingDocuments = false;
                    this.showingFiltersPane = false;
                    this.loadingMain = false;
                }
            }, 
            error: error => {
                this.notificationService.showNotification(`An unexpected error occurrred. ${error?.error ? error.error : ''}`, 'error');
            }
        });
    }

    cleanUpLargeIcons(docs: Document[]): Document[] {
        var len = docs.length;
        
        for (let i = 0; i < len; i++) if (docs[i].thumbnailUrl == undefined || docs[i].thumbnailUrl == null || docs[i].thumbnailUrl == '') {
            docs[i].thumbnailUrl = 'assets/images/generic-document.png';
        } else {
            docs[i].thumbnailUrl = buildUrl(this.blobUrl, this.sas, docs[i].thumbnailUrl);
        }

        return docs;
    }

    getFileTypeCount(docs: Document[]): number[] {
        var temp: number[] = [];

        for (let i = 0; i < docs.length; i++) {
            let tokens = docs[i].fileName.split('.');
            let ext = tokens[tokens.length - 1].toLowerCase();
            if (temp[ext] == undefined) temp[ext] = 0;
            temp[ext]++;
        }
        var counts = [];

        for (var key in temp) counts[counts.length] = [key, temp[key]];
        return counts;
    }

    buildLibraryRequestForCurrentView(): LibraryRequest {
        var wayFinderFilters = [];

        this.currentURLWayFinders.forEach((filter: Filter, index: number) => {
            if (filter.referenceType != ReferenceType.Null) wayFinderFilters.push(filter);
        });

        this.queryID++;

        let searches: SearchText[] = [];
        this.currentURLWayFinders.forEach((filter: Filter, index: number) => {
            if (this.searchStrings[index] != undefined && this.searchStrings[index] != '') searches.push({ ReferenceType: filter.referenceType, Text: this.searchStrings[index] });
        });

        let libraryRequest: LibraryRequest = {
            FileNameFilter: this.currentSearchString,
            Recent: false,
            Starred: false,
            RecentlyUploaded: false,
            Offset: this.offset,
            SortBy: DocumentSortingMethod.NameAscending,
            ReturnFilters: false,
            Filters: [...wayFinderFilters, ...this.currentURLFilters],
            Superseded: this.supersededVersions,
            GroupChronologically: false,
            QueryId: this.queryID,
            TextSearches: searches
        }
        return libraryRequest;
    }

    buildDocumentIndices(): void {
        this.documentIndices = [];
        for (var i in this.documents) {
            this.documentIndices[this.documents[i].id] = parseInt(i);
        }
    }

    filterShouldBeChecked(referenceType: ReferenceType, id: number): boolean {
        if (this.currentURLFilterLookup[referenceType] !== undefined && this.currentURLFilterLookup[referenceType][id] !== undefined) return true;
        if (this.currentURLWayFinderLookup[referenceType] !== undefined && this.currentURLWayFinderLookup[referenceType][id] !== undefined) return true;
        return false;
    }

    // isAdditionalLoad - only true when we are loading more data after scrolling
    processLibraryPageResult(result: LibraryPageResult, isAdditionalLoad: boolean = false, originalRequest: LibraryRequest): void {
        
        if (result.queryId != this.queryID) return;

        this.currentViewItemTotal = result.totalResultsAvailable;

        let docs = this.cleanUpLargeIcons(result.libraryItems);
        
        if (!isAdditionalLoad) this.documents = docs;
        else docs.forEach((document: Document, index: number) => {
            this.documents.push(document);
        });

        this.buildDocumentIndices();

        this.documentFileTypes = this.getFileTypeCount(result.libraryItems);

        if (!isAdditionalLoad) {
            let origFilter: boolean[] = [];
            originalRequest.Filters.forEach((filter: Filter, index: number) => {
                origFilter[filter.referenceType + '.' + filter.id] = true;
            });
            result.filters.forEach((filter: Filter, index: number) => {
                filter.checked = filter.itemCount == result.totalResultsAvailable;
                if (!filter.checked) filter.checked = this.currentURLFilterLookup[filter.referenceType] !== undefined && this.currentURLFilterLookup[filter.referenceType][filter.id] !== undefined;
                if (filter.checked && origFilter[filter.referenceType + '.' + filter.id] === undefined) filter.automatic = true;
            });
            result.categoryGroups.forEach((group: FilterGroup, index: number) => {
                group.filters.forEach((filter: Filter, index: number) => {
                    filter.checked = filter.itemCount == result.totalResultsAvailable;
                    if (filter.checked && origFilter[filter.referenceType + '.' + filter.id] === undefined) filter.automatic = true;
                });
            });
            this.navigationFilters = new FiltersSet(result.filters, result.categoryGroups, FiltersSetBehaviour.SelectLonelyFilters);
        }

        this.offset = result.offset;
        if (this.view == 'recent' || this.view == 'starred') {
            let total: number = 0;
            result.chronologicallyGroupedLibraryItems.forEach((docGrp: DocumentGroup) => {
                total += docGrp.libraryItems.length;
            });
            this.totalRecordsAvailable = total;
            this.currentViewItemTotal = total;
        } else this.totalRecordsAvailable = result.totalResultsAvailable;

        if (!isAdditionalLoad) {
            this.chronologicallyGroupedDocuments = result.chronologicallyGroupedLibraryItems;
            if (this.chronologicallyGroupedDocuments != null) {
                this.chronologicallyGroupedDocuments.forEach((group: DocumentGroup, index: number) => {
                    group.name = expandCamelCase(group.name);
                    group.libraryItems = this.cleanUpLargeIcons(group.libraryItems);
                });
            }
        }

        this.loadingMoreData = false;
        this.loadingMain = false;

    }

    refreshDocuments(): void {
        this.offset = 0;
        this.getLibraryPage();
    }

    getLibraryPage(): void {
        this.loadingMain = true;
        this.currentLocation = 'Library';
        this.currentPageType = ReferenceType.Library;
        if (this.view == 'home') {
            this.queryID++;

            var glhpr: GetLibraryHomePageRequest =
            {
                QueryId: this.queryID
            };

            this.http
                .post<LibraryHomePageResult>(`${this.applicationConfig.applicationUri}/v2/document/gethomepage/`, glhpr)
                .subscribe({
                    next: result => {
                        this.homePageStarred = this.cleanUpLargeIcons(result.starred);
                        this.homePageStarredTotal = result.totalStarredCount;
                        this.homePageStarredFileTypes = this.getFileTypeCount(result.starred);
                        this.homePageRecent = this.cleanUpLargeIcons(result.recents);
                        this.homePageRecentTotal = result.totalRecentCount;
                        this.homePageRecentFileTypes = this.getFileTypeCount(result.recents);
                        result.recentlyUploaded = result.recentlyUploaded.slice(0, 10);
                        result.totalRecentlyUploadedCount = result.recentlyUploaded.length;
                        this.homePageRecentlyUploaded = this.cleanUpLargeIcons(result.recentlyUploaded);
                        this.homePageRecentlyUploadedTotal = result.totalRecentlyUploadedCount;
                        this.homePageRecentlyUploadedFileTypes = this.getFileTypeCount(result.recentlyUploaded);
                        this.totalRecordsAvailable = result.totalDocumentCount;
                        this.allDocumentsUrl = '/home/documents/view/all';
                        this.loadingMain = false;
                    },
                    error: error => {
                        this.notificationService.showNotification(`An unexpected error occurrred. ${error?.error ? error.error : ''}`, 'error');
                    }
                });

        } else {
            let libraryRequest = this.buildLibraryRequestForCurrentView();
            if (this.view == 'starred') {
                libraryRequest.Starred = true;
                libraryRequest.GroupChronologically = true;
                libraryRequest.Superseded = true;
            } else if (this.view == 'recent') {
                libraryRequest.Recent = true;
                libraryRequest.GroupChronologically = true;
                libraryRequest.Superseded = true;
            } else if (this.view == 'all') {
                this.allDocumentsUrl = this.router.url;
            }

            this.http
                .post<LibraryPageResult>(`${this.applicationConfig.applicationUri}/v2/document/getdocumentsandfilters`, libraryRequest)
                .subscribe({
                    next: result => {
                        this.processLibraryPageResult(result, false, libraryRequest);
                    },
                    error: error => {
                        this.notificationService.showNotification(`An unexpected error occurrred. ${error?.error ? error.error : ''}`, 'error');
                    }
                });
        }
    }

    scrollListener(event) {
        if (this.view == 'home') return;

        if (event.srcElement.scrollHeight - event.srcElement.scrollTop - event.srcElement.clientHeight <= 7500) {
            if (this.viewingDocuments)
            {
                this.getNextLibraryPage();
            }
        }
    }

    getNextLibraryPage() {
        if (this.loadingMoreData) return;
        if (this.offset >= this.totalRecordsAvailable) return;

        this.loadingMoreData = true;

        let libraryRequest = this.buildLibraryRequestForCurrentView();
        this.http
            .post<LibraryPageResult>(`${this.applicationConfig.applicationUri}/v2/document/getdocumentsandfilters`, libraryRequest)
            .subscribe({
                next: result => {
                    this.processLibraryPageResult(result, true, libraryRequest);
                    this.loadingMoreData = false;
                },
                error: error => {
                    this.notificationService.showNotification(`An unexpected error occurrred. ${error?.error ? error.error : ''}`, 'error');
                    this.loadingMoreData = false;
                }
            });
    }

    async filterClicked(event: any, referenceType: ReferenceType, id: string): Promise<void> {
        this.navigationFilters.setChecked(referenceType, id, event.target.checked, false);
        // Check the wayfinders have not been dirsupted by the filter click.
        // In case they have been I'll start building the new URL accordingly...
        let wayfinderStateOk: boolean = true;
        let filterStr = '';
        let setFilters: boolean[] = [];

        this.currentURLWayFinders.forEach((filter: Filter, index: number) => {
            if (filter.referenceType == referenceType && filter.id == id) wayfinderStateOk = false;
            else {
                filterStr = filterStr + 'f.' + filter.referenceType + '.' + filter.id + '=true';
                setFilters[filter.referenceType + '.' + filter.id] = true;
            }
        });

        if (!wayfinderStateOk) {

            this.navigationFilters.getSelectedFilters(false).forEach((filter: Filter, index: number) => {
                if (setFilters[filter.referenceType + '.' + filter.id] === undefined) filterStr = filterStr + (filterStr.length > 0 ? '&' : '') + 'f.' + filter.referenceType + '.' + filter.id + '=true';
            });

            let paramStr: string = this.currentSearchString.length > 0 ? 'search=' + encodeURIComponent(this.currentSearchString) : '';

            if (filterStr.length > 0) paramStr = paramStr + (paramStr.length > 0 ? '&' : '') + filterStr;

            let url = '/home/documents/view/all';

            if (paramStr.length > 0) url += '?' + paramStr;

            await this.router.navigateByUrl(url);
        } else {
            // wayfinders weren't disrupted after all, so just navigate the usual way
            this.navigate();
        }
    }

    newWayFinderImage(url: string, id: string) {
        this.wayFinderTileGroups.forEach((wftg: WayFinderTileGroup) => {
            wftg.tiles.forEach((tile: WayFinderTile) => {
                if (tile.id == id) {
                    tile.thumbnailUrl = url;
                    tile.defaultImage = false;
                }
            });
        });
    }
}

class Debug {
    id: number;
    userId: string;
    message: string;
    when: string;
}


export interface WayFinderResult {
    wayFinders: Filter[],
    nextLevel: FilterGroup[],
    documentCount: number,
    queryId: number
}

export interface LibraryRequest {
    FileNameFilter: string,
    Recent: boolean,
    Starred: boolean,
    RecentlyUploaded: boolean,
    Offset: number,
    SortBy: DocumentSortingMethod,
    ReturnFilters: boolean,
    Filters: Filter[],
    Superseded: boolean,
    GroupChronologically: boolean,
    QueryId: number;
    TextSearches: SearchText[];
}

export enum DocumentSortingMethod {
    NameAscending = 1,
    NameDescending = 2,
    SizeAscending = 3,
    SizeDescending = 4,
    CreatedAscending = 5,
    CreatedDescending = 6,
    ModifiedAscending = 7,
    ModifiedDescending = 8
}

export interface LibraryHomePageResult {
    recents: Document[];
    totalRecentCount: number;
    starred: Document[];
    totalStarredCount: number;
    recentlyUploaded: Document[];
    totalRecentlyUploadedCount: number;
    totalDocumentCount: number;
    queryId: number;
}

export interface LibraryPageResult {
    libraryItems: Document[];
    filters: Filter[];
    categoryGroups: FilterGroup[];
    offset: number;
    totalResultsAvailable: number;
    chronologicallyGroupedLibraryItems: DocumentGroup[];
    queryId: number;
}
interface GetLibraryHomePageRequest {
    QueryId: number;
}

interface GetReportsResponse {
  reports: Report[];
}