//angular imports
import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

//local imports
import { Persona } from '../_interfaces/persona';
//import { PersonaEnum } from '../_enums/persona';
import { AppConfig } from '../app-config';

@Injectable({
  providedIn: 'root'
})
export class PersonaService {
  public currentPersona = signal<Persona>(null);
  public allPersonas = signal<Persona[]>([]);

  private personasLoaded = new BehaviorSubject<boolean>(false);
  private personaChanged = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private applicationConfig: AppConfig,
    private authenticationService: MsalService,
  ) {
    var currentUser = this.authenticationService.instance.getActiveAccount();
    if (currentUser !== undefined) {
      const claims = currentUser.idTokenClaims['personas'] as string[];
      if (claims !== undefined) {
        this.http.get<GetAllPersonasResponse>(`${this.applicationConfig.applicationUri}/v2/persona`)
          .subscribe(response => {
            let personaList: Persona[] = [];
            claims.forEach((id) => {
              let persona = response.personas.find(p => p.id == id);
              personaList.push(persona);
            });

            console.log('personas:', personaList);
            this.allPersonas.set(personaList);
            if (personaList.length > 0) {
              this.currentPersona.set(personaList[0]);
            }
            this.personasLoaded.next(true);
          });
      }
    }
  }

  public setPersona(p: Persona) {
    this.currentPersona.set(p);
    this.personaChanged.next(true);
  }

  public changed() {
    return this.personaChanged.asObservable();
  }

  public initialized() {
    return this.personasLoaded.asObservable();
  }
}

class GetAllPersonasResponse {
  personas: Persona[];
}